export const translationES = {
  //login
  portal_patient: "Portal del Paciente",
  login__title: "Iniciar Sesión",
  login__placeholder_email: "Correo Electrónico",
  login__placeholder_password: "Contraseña",
  login__button: "Iniciar Sesión",
  login__fields: "Todos los campos son requeridos",
  login__is_not_mediexcel:
    "Su cobertura MediExcel ha terminado. \n" +
    "Favor de hacer clic en el enlace <a href='/excel/login/'>Ir a Excel</a> para ingresar a su portal como paciente de Hospital Excel. \n" +
    "¿Tiene problemas para ingresar? Contáctenos a sales@mediexcel.com",
  login__is_not_excel:
    "Hemos detectado que cuenta con una cobertura MediExcel. \n" +
    "Favor de hacer clic en el enlace <a href='/mediexcel/login/'>Ir a MediExcel</a> para ingresar a su portal como paciente MediExcel. \n" +
    "¿Tiene problemas para ingresar? Contáctenos a sales@mediexcel.com",
  login__email_confirm_pending:
    "Su correo electrónico no ha sido validado. \n" +
    "\n" +
    "¿Tiene problemas para ingresar? Contáctenos a sales@mediexcel.com",
  login__mediexcel_expired: "Cobertura vencida de MediExcel",
  login__excel_new_coverage: "Cobertura nueva de Excel",
  login__account_not_found: "Cuenta no encontrada",
  login__invalid_credentials: "Credenciales no válidas",
  signup_forgot_password: "Olvidé mi contraseña",
  powered__by: "Operado Por",
  powered__by_portal: "Portal del Paciente",
  //signup
  signup__title: "Registro",
  signup__placeholder_first_name: "Nombre",
  signup__placeholder_last_name: "Apellidos",
  signup__placeholder_date_of_birth: "Fecha de Nacimiento",
  signup__placeholder_member: "No. De Miembro",
  signup__placeholder_telephone: "Teléfono",
  signup__placeholder_confirm_password: "Confirmar Contraseña",
  signup__button: "Registrar",
  signup__password_short: "Contraseña demasiado corta",
  signup__password_match: "Las contraseñas no coinciden",
  signup__invalid_name: "Nombre inválido",
  signup__invalid_credential:
    "El número de miembro MediExcel que ha proporcionado es inválido.  " +
    "Favor de verificarlo ó cambiar al perfil de paciente privado de Hospital Excel.",
  signup__invalid_password: "Contraseña invalida",
  signup__invalid_phone: "Teléfono inválido ",
  signup__invalid_patient: "Paciente invalido",
  signup__invalid_info:
    "La información proporcionada no es válida los datos del usuario no existen",
  signup__existing_patient_portal: "Portal del paciente existente",
  signup__existing_user_email: "Correo electrónico de usuario existente",
  signup__account_create: "Cuenta creada",
  signup__agreement_text:
    "Al hacer clic en el botón Registrar, usted acepta acceder a nuestro portal y " +
    "recibir todos los documentos, avisos, anuncios y encuestas de forma electrónica, incluso los documentos del plan de salud.",
  signup__accept_receive_email_checkbox:
    "Acepto él envió de correos de Información",
  signup__accept_receive_email: "Acepto él envió de correos",
  //forgot_passwor
  forgot_password__title: "Restablecer Contraseña",
  forgot_password__message:
    "Le enviaremos un correo con instrucciones para modificarla",
  forgot_password__button: "Enviar correo",
  forgot_password__input: "Por favor ingresar su correo",
  //navbar
  navbar__welcome: "Bienvenido/a",
  navbar__profile: "Perfil",
  navbar__logout: "Cerrar Sesión",
  //postregister
  postregister__message:
    "Usted ha recibido un correo de verificación de su cuenta. Favor de validar su correo antes de ingresar.",
  postregister__go_to_login: "Ir a Iniciar Sesión",
  //login-sigup
  auth__server_error: "Error del Servidor",
  auth__invalid_email: "Correo no válido",
  auth__go_to_mediexcel: "Ir a MediExcel",
  auth__go_to_excel: "Ir a Excel",
  //Change Password
  change_password: "Cambiar Contraseña",
  change_password__button: "Enviar",
  change_password_confirmed: "Su contraseña ha sido actualizada",
  change_password__placeholder_password: "Nueva Contraseña",
  change_password__placeholder_confirm_password: "Confirmar Nueva Contraseña",
  change__password__message:
    "Usted ha recibido un correo de verificación de su cuenta. Favor de validar su correo antes de ingresar.",
  //others
  change_language_english: "Inglés",
  change_language_spanish: "Español",
  footer: "Todos los derechos reservados.",
  developed_by: "Desarrollado por",
  addresses: "Direcciones",
  phones: "Teléfonos",
  emails: "Correos",
  find_doctor: "//excel.network/physicians/provider-list/?lang=mx-es",
  pdf__download_credential: "Descargar",
  //Profile
  profile: "Perfil",
  personal_data: "Información Personal",
  contact_data: "Información de Contacto",
  change_email: "Cambiar Correo",
  change_personal_data: "Cambiar Información Personal",
  profile__send_request:
    "Hemos enviado su solicitud a nuestro Equipo de Manejo de Cuentas. \n" +
    "Se pondrán en contacto con usted para hacer él(los) cambio(s) requerido(s).",
  profile__send_request_excel:
    "Hemos enviado su solicitud a nuestro Equipo de Recepción. \n" +
    "Se pondrán en contacto con usted para hacer él(los) cambio(s) requerido(s).",
  profile__counter_pendings: "Número de solicitudes pendientes de atender",
  request_info__title: "Por favor redacte su solicitud",
  changemail__email_exists:
    "El correo que ingresaste ya está en uso en el sistema",
  changemail__validation_sended:
    "solicitud aceptada se ha enviado un correo para verificar los cambios",
  change__mail_message:
    "Se ha enviado un correo de verificación de su cuenta. Favor de validar su correo.",
  message__go_to_origin: "Ir a inicio",
  //Menu
  my_mediexcel_plan: "Mi MediExcel",
  my_appointments: "Mis Citas",
  my_labs: "Mis Exámenes de Laboratorio",
  my_cabinets: "Mis Gabinetes",
  //My Health Plan
  my_health_plan: "Mi MediExcel",
  my_health_plan__info: "Información de mi Cobertura",
  my_health_plan__digital_id: "Tarjeta MediExcel",
  my_health_plan__find_doctor: "Directorio",
  my_health_plan__providers: "Atención de Urgencia",
  my_health_plan__plan: "Mis Planes",
  my_health_plan__videos: "Videos",
  my_health_plan__videos_no_video: "No hay videos disponibles",
  my_health_plan__dependents: "Mis Dependientes",
  my_health_plan__placeholder_credentials: "ID del Miembro",
  my_health_plan__certificate: "Certificado de Cobertura",
  my_health_plan__document_request: "Documentos más Solicitados",
  my_health_plan__placeholder_Name: "Nombre",
  my_health_plan__placeholder_DOB: "Fecha de Nacimiento",
  my_health_plan__placeholder_Medical_Plan: "Mi Plan Médico",
  my_health_plan__placeholder_Vision_Plan: "Mi Plan Visión",
  my_health_plan__placeholder_Dental_Plan: "Mi Plan Dental",
  my_health_plan__placeholder_Member_Number: "Número de miembro",
  my_health_plan__city: "Mexicali / Condado Imperial",
  my_dependents: "Mis Dependientes",
  dependents_message: "Dependiente mayor de 13 años",
  message_privacity:
    "Para cumplir con las regulaciones federales y estatales, " +
    "MediExcel Health Plan restringe el acceso a información de salud personal de cualquier miembro de 13 años o más.",
  //My plans
  my_plans__copays: "Copagos",
  my_plans__medical_plan_title: "Médico",
  my_plans__dental_plan_title: "Dental",
  my_plans__vision_plan_title: "Visión",
  my_plans__description_copay:
    "Esta es una lista parcial de beneficios y copagos. " +
    "Haga clic en el botón de 'Documentos de mi Plan' a continuación para ver su Evidencia de Cobertura y obtener más detalles. " +
    "Si usted tiene alguna pregunta adicional, comuníquese a la Línea de Servicio al Miembro al 619-365-4346 (US) o 664-633-8555 (MX).",
  my_plans__description_no_copay:
    "Esta es una lista parcial de beneficios. " +
    "Haga clic en el botón de 'Documentos de mi Plan' a continuación para ver su Evidencia de cobertura y obtener más detalles. " +
    "Si usted tiene preguntas adicionales, comuníquese con nuestra Línea de Servicio para Miembros al 619-365-4346 (US) O 664-633-8555 (MX).",
  my_plans__document_button: "Documentos de mi Plan",
  my_labs__request: "Mis Solicitudes de Laboratorio",
  my_requests: "Solicitud",
  my_labs__result: "Mis Exámenes de Laboratorio",
  my_labs__results: "Resultados de Laboratorio",
  my_lab__request: "Usted no tiene solicitudes de Laboratorio.",
  my_lab__result_not_found: "Usted no tiene resultados de Laboratorio.",
  //not found
  not_found__title: "Página no encontrada",
  not_found__content:
    "Es posible que la página que está buscando se ha sido eliminada, ha cambiado de nombre o no está disponible temporalmente",
  my_labs__requests: "Solicitudes de Laboratorio",
  my_labs__requests_type: "Tipo",
  my_labs__requests_date: "Fecha",
  my_labs__requests_studies: "# de Estudios",
  my_labs__requests_detail: "Detalle",
  my_health_plan_dependents__not_found: "Usted aún no tiene dependientes.",
  my_health_medical_providers__911:
    "Si usted está experimentando una emergencia que pone en peligro su vida, llame al 911 o acuda a la sala de emergencia más cercana. ",
  request__CoC:
    "Estamos trabajando en su solicitud. Le enviaremos un correo una vez que esté disponible. \n" +
    "Importante: Si usted no ha recibido este documento en un lapso de 48 horas, favor de contactar a Aplicaciones",
  request__1095b:
    "Estamos trabajando en su solicitud. El equipo 1095B le contactará una vez que su forma esté disponible. \n" +
    "Importante: Si no ha recibido este documento en un lapso de 48 horas, favor de contactar a 1095@mediexcel.com",
  schedule_appointment: "Agendar Cita",
  in_person: "En Persona",
  virtual_care: "Cuidado Virtual",
  upcoming_apps: "Próximas Citas",
  no_apps: "Usted no tiene citas agendadas.",
  new_appointment: "Nueva Cita",
  specialty: "Especialidad",
  doctor: "Doctor",
  location: "Locación",
  reason: "Motivo de la cita *",
  date: "Fecha *",
  time: "Hora *",
  second_option: "Segunda Opción",
  third_option: "Tercera Opción",
  newapp__agreement_text:
    "Si no recibes un correo de confirmación, favor de comunicarse con Servicio al Miembro al correo " +
    "memberservices@mediexcel.com o llamar al 619-365-4346 (US) o 664-633-8555 (MX)",
  send_done:
    "Su solicitud fue enviada correctamente a nuestro Equipo de Servicio al Miembro, en la brevedad alguien se comunicará con usted",
  time_label_table: "Hora",
  doctor_label_table: "Doctor",
  specialty_label_table: "Especialidad",
  appoiment_specialty__primary: "Atención primaria",
  appoiment_specialty__pediatrics: "Pediatría",
  appoiment_specialty__gynecology: "Ginecología",
  appoiment_specialty__nutrition: "Nutrición",
  appoiment_specialty__psychology: "Psicología",
  appoiment_specialty__dental: "Dental",
  appoiment_specialty__vision: "Visión",
  appoiment_doctor__regular: "Mi Doctor de Cabecera",
  appoiment_doctor__next: "Próximo Doctor Disponible",
  specialty: "Especialidad *",
  doctor: "Doctor *",
  location: "Ubicación *",
  hour: "Hora *",
  loading: "Cargando...",
  profile__request_body_error:
    "Por favor ingrese un mínimo de 10 caracteres y un máximo de 350 caracteres",
  text_urgent_care_provider_locator: "Localizador de Proveedores de Atención de Urgencia",
  text_emergency: "Si tienes una emergencia y corre peligro tu vida, marca al 911 o acude a la sala de emergencia más cercana.",
  find_button: "BUSCAR",
  search_location: "Ubicacion de busqueda",
  use_current_location: "Ubicación Actual",
  go_back: "Volver",
  website: "Sitio web",
  laboratory: "Laboratorio",
  not_available: "No disponible",
  text_estimated_time: "Tiempo estimado de llegada",
  minute: "minuto",
  minutes: "minutos",
  hour: "hora",
  hours: "horas",
  sort_by_distance_for_the_current_location: "Ordenar por distancia para la ubicación actual",
  search_by_provider: "Buscar por Proveedor",
  search_by_location: "Buscar por Ubicación",
  search_provider: "Buscar Proveedor",
  search_location: "Buscar Ubicación",
  imaging_services: "Servicios de imágenes",
  search_your_location: "Busca ubicación",
  real_location: "Tu ubicación actual",
  distance: "Distancia",
  return_to_my_location: "Volver a mi ubicación",
  opcion: "Opción",
  opcion_one: "Opción 1",
  opcion_two: "Opción 2",
  text_write: "Escribe tu dirección o el nombre del proveedor dentro de la barra de búsqueda y dale clic al botón",
  text_write_google: "haz clic en el icono de globo a continuación y permite que Google utilice tu ubicación actual para la búsqueda.",
  no_nearby_places: "Sin lugares cercanos",
  estimated_time_not_available: "Tiempo estimado de llegada no disponible",
  text_find: "Localiza la clínica de atención de urgencia más cercana, sin importar dónde te encuentres."
};
