import React, { useState, useEffect, useContext, useCallback } from "react";
import LocationRed from "../../assets/images/location-pin-svgrepo-com.svg";
import LocationGreen from "../../assets/images/location-green.svg";
import { MapContext } from "context/MapContext";
import { useTranslation } from "react-i18next";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  OverlayView,
  Circle,
} from "@react-google-maps/api";

const libraries = ["places"];

const MarkerLabel = ({ text }) => (
  <div
    style={{
      width: "200px",
      color: "#555",
      fontSize: "12px",
      fontWeight: "bold",
      position: "absolute",
      left: "-35px",
    }}
  >
    {text}
  </div>
);

export default function MapComponent() {
  const {
    actualLocation,
    searchRadiusKm,
    locations,
    estimatedTimes,
    calculateDistance,
    selectedClinicId,
    zoom,
    setZoom,
    onMapLoad,
    searchType
  } = useContext(MapContext);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [centerInto, setCenterInto] = useState(null);
  const [mapStyle, setMapStyle] = useState({
    width: "800px",
    height: "300px",
    borderRadius: "10px",
  });
  const [showCurrentLocationInfo, setShowCurrentLocationInfo] = useState(false);
  const { t } = useTranslation();

  const onMapClick = useCallback((e) => {
    setZoom(14);
  }, []);

  useEffect(() => {
    const location_find = locations?.find(
      (clinic) => clinic.id === selectedClinicId
    );

    setSelectedMarker(location_find);

    if (location_find && location_find.lat_long) {
      const [lat, lng] = location_find.lat_long.split(",").map(Number);
      setCenterInto({ lat, lng });
    } else {
      console.error("No se encontró la clínica o lat_long no está definido");
    }
  }, [selectedClinicId, locations]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setMapStyle({
          width: "350px",
          height: "250px",
          borderRadius: "10px",
        });
      } else if (window.innerWidth <= 576) {
        setMapStyle({
          width: "400px",
          height: "250px",
          borderRadius: "10px",
        });
      } else if (window.innerWidth <= 768) {
        setMapStyle({
          width: "600px",
          height: "250px",
          borderRadius: "10px",
        });
      } else {
        setMapStyle({
          width: "900px",
          height: "300px",
          borderRadius: "10px",
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk",
    libraries,
  });

  if (loadError) return "Error loading map";
  if (!isLoaded) return "Loading...";

  return (
    <>
      <GoogleMap
        id="map"
        mapContainerStyle={mapStyle}
        zoom={zoom}
        center={centerInto || actualLocation}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        <Marker
          key="current-location"
          position={actualLocation}
          icon={LocationRed}
        />

        <Marker
          key="current-location-red"
          position={actualLocation}
          icon={LocationRed}
          onClick={() => setShowCurrentLocationInfo(true)}
        />

        {showCurrentLocationInfo && (
          <InfoWindow
            position={actualLocation}
            onCloseClick={() => setShowCurrentLocationInfo(false)}
          >
            <div style={{ color: "#333" }}>
              <h4>{t("real_location")}</h4>{" "}
            </div>
          </InfoWindow>
        )}

        {locations?.map((location) => {
          const [lat, lng] = location.lat_long.split(",").map(Number);
          return (
            <Marker
              key={`fake-${location.id}`}
              icon={LocationGreen}
              position={{ lat, lng }}
              onClick={() => setSelectedMarker(location)}
            >
              <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                  x: -(width / 2),
                  y: height + 5,
                })}
              >
                <MarkerLabel text={location.provider} />
              </OverlayView>
            </Marker>
          );
        })}

        <Circle
          center={actualLocation}
          radius={searchRadiusKm}
          options={{
            fillColor: "blue",
            fillOpacity: 0.2,
            strokeColor: "red",
            strokeOpacity: 0.5,
            strokeWeight: 2,
          }}
        />

        {locations?.map((location) => {
          const [lat, lng] = location.lat_long.split(",").map(Number);

          const estimatedTime = estimatedTimes.find(
            (time) => time.provider === location.provider
          );

          const distanceKm = actualLocation
            ? calculateDistance(
                actualLocation.lat,
                actualLocation.lng,
                lat,
                lng
              )
            : null;

          return (
            <Marker
              key={`marker-${location.id}`}
              icon={LocationGreen}
              position={{ lat, lng }}
              onClick={() => setSelectedMarker(location)}
            >
              {selectedMarker === location && (
                <InfoWindow
                  position={{ lat, lng }}
                  onCloseClick={() => setSelectedMarker(null)}
                  pixelOffset={{ width: 0, height: -30 }}
                >
                  <div style={{ color: "#333" }}>
                    <h4>{location.provider || `${t("real_location")}`}</h4>
                    <p>
                      {estimatedTime && distanceKm !== null ? (
                        <>
                          {t('distance')}: {distanceKm.toFixed(1)} km
                          <br />
                          {t('text_estimated_time')}{" "}
                          {estimatedTime.estimatedTime.hours}{" "}
                          {estimatedTime.estimatedTime.hours > 1
                            ? t('hours')
                            : t('hour')}
                            {" "}
                          {estimatedTime.estimatedTime.minutes}{" "}
                          {estimatedTime.estimatedTime.minutes > 1
                            ? t('minutes')
                            : t('minute')}
                        </>
                      ) : (
                        <></>
                        // <p>{t('estimated_time_not_available')}</p>
                      )}
                    </p>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    </>
  );
}
