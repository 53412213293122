import React, { useContext } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {
    FaUserAlt,
    FaCreditCard,
    FaSearch,
    FaDatabase,
    FaPhotoVideo,
    FaUsers
} from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import location_black from "../../../assets/images/location_black.png";
import { FaOutdent } from "react-icons/fa";
import DataContent from "./DataContent";
import { MapProvider } from "context/MapContext";
import { MenuContext } from "context/menuContext";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SideBar = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 22rem;
  max-width: 22rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
  border-radius: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SideBarTitle = styled.div`
  height: 4rem;
  padding: 1rem 0.5rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const SideBarContent = styled.button`
  display: flex;
  color: black;
  align-items: center;
  padding: 1.5rem;
  list-style: none;
  height: 3.5rem;
  text-decoration: none;
  margin: .2rem;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    background: #FAF9F6;

    button {
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

const SideBarContentFD = styled.a`
  display: flex;
  color: black;
  align-items: center;
  padding: 1.5rem;
  list-style: none;
  height: 3.5rem;
  text-decoration: none;
  margin: .2rem;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    background: #FAF9F6;

    a {
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

const SidebarLabel = styled.button`
  margin-left: 1rem;
  font-size: 1.2rem; 
  background-color: transparent;
  border: none;

  &:disabled {
    cursor: not_allowed;
    color: gray;
  }
`;

const SidebarLabelFD = styled.a`
  margin-left: 1rem;
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: black;

  &:disabled {
    cursor: not_allowed;
    color: gray;
  }
`;

const Content = styled.div`
  max-height: calc(100vh - 14rem);
  overflow-y: scroll;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: ${({active_component}) =>
          active_component === "my_health_plan__find_doctor" ? "0" : "1rem"};

  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const ShowMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Profile = () => {
  const { handleChange, active_component, handleIconClick, toggleVideo  } = useContext(MenuContext);
    const { t } = useTranslation();

    return (
      <>
        <Container>
          <ShowMenu onClick={toggleVideo}>
            <i onClick={handleIconClick}><FaOutdent size={35} color='#005487' /> </i>
          </ShowMenu>
            <SideBar>
                <SideBarTitle data-testid="profile-header">
                    {t("my_health_plan")}
                </SideBarTitle>
                <SideBarContent name="my_health_plan__info" onClick={handleChange}>
                    <FaUserAlt/>
                    <SidebarLabel name="my_health_plan__info" onClick={handleChange}>
                        {t("my_health_plan__info")}
                    </SidebarLabel>
                </SideBarContent>
                <SideBarContent name="my_health_plan__plan" onClick={handleChange}>
                    <FaDatabase/>
                    <SidebarLabel name="my_health_plan__plan" onClick={handleChange}>
                        {t("my_health_plan__plan")}
                    </SidebarLabel>
                </SideBarContent>
                <SideBarContent name="my_health_plan__digital_id"
                                onClick={handleChange}>
                    <FaCreditCard/>
                    <SidebarLabel
                        name="my_health_plan__digital_id"
                        onClick={handleChange}
                    >
                        {t("my_health_plan__digital_id")}
                    </SidebarLabel>
                </SideBarContent>
                <SideBarContent name="my_health_plan__dependents"
                                onClick={handleChange}>
                    <FaUsers/>
                    <SidebarLabel
                        name="my_health_plan__dependents"
                        onClick={handleChange}
                    >
                        {t("my_health_plan__dependents")}
                    </SidebarLabel>
                </SideBarContent>
                <SideBarContentFD
                    target="_blank"
                                href={t("find_doctor")}>
                    <FaSearch/>
                    <SidebarLabelFD
                        data-testid="find-doctor-link"
                        target="_blank"
                        href={t("find_doctor")}
                    >
                        {t("my_health_plan__find_doctor")}
                    </SidebarLabelFD>
                </SideBarContentFD>
                <SideBarContent name="my_health_plan__providers"
                                onClick={handleChange}>
                    <img src={location_black} width={20} height={20} alt="location black" />
                    <SidebarLabel
                        data-testid="medical-provider-link"
                        name="my_health_plan__providers"
                        onClick={handleChange}
                    >
                        {t("my_health_plan__providers")}
                    </SidebarLabel>
                </SideBarContent>

                <SideBarContent name="my_health_plan__videos"
                  onClick={handleChange}>
                    <FaPhotoVideo/>
                    <SidebarLabel
                        data-testid="videos-link"
                        name="my_health_plan__videos"
                        onClick={handleChange}
                    >
                        {t("my_health_plan__videos")}
                    </SidebarLabel>
                </SideBarContent>

            </SideBar>
            <Content active_component={active_component}>
              <MapProvider>
                <DataContent isActive={active_component}/>
              </MapProvider>
            </Content>
          </Container>
      </>
    );
};

export default Profile;
