import React, { useContext } from "react";
import { MapContext } from "context/MapContext";
import { useTranslation } from "react-i18next";
import { SearchInput } from "components/pages/MapUrgentCareProviders/styles";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import styled from "styled-components";

const SuggestionsList = styled.ul`
  position: fixed;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  width: 300px;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export function SearchLocation() {
  const { setSearchValue, panTo, setAdressLocation, setCoordinates } = useContext(MapContext);
  const { t } = useTranslation();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    setSearchValue(inputValue);
  };

  /**
   * Funci n que se llama cuando se selecciona un lugar de la lista de sugerencias.
   * Limpia el input de b squeda y muestra el marcador en el mapa en la ubicaci n seleccionada.
   * @param {string} address La direcci n del lugar seleccionado.
   * @return {Promise<void>}
   */
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      setAdressLocation(results);
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({ lat, lng });
      panTo({ lat, lng });
    } catch (error) {
      console.error("Error obteniendo coordenadas: ", error);
    }
  };

  return (
    <>
      <SearchInput
        type="text"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t('search_your_location')}
        aria-label="Search your location"
      />
      {status === "OK" && data.length > 0 && (
        <SuggestionsList>
          {data.map(({ place_id, description }) => (
            <SuggestionItem key={place_id} onClick={() => handleSelect(description)}>
              {description}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </>
  );
}
