import React, { useContext } from "react";
import { MenuContext } from "context/menuContext";
import {useTranslation} from "react-i18next";
import {
    FaUserAlt,
    FaCreditCard,
    FaSearch,
    FaDatabase,
    FaPhotoVideo,
    FaUsers,
} from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import location_black from "../../assets/images/location_black.png"
import { 
    FadeInLeftDiv, 
    MenuFixed, 
    MenuList, 
    MenuItem,
    Link,
    SideBarTitle,
    SideBarContent,
    SidebarLabel,
    SidebarLabelFD,
    SideBarContentFD,
    MenuScroll
} from "./styles/Menu";

export function Menu({ showMenu, setShowMenu, setActiveComponent }) {
  const {t} = useTranslation();
  const { handleIconClick } = useContext(MenuContext);

  const handleChange = (e) => {
    setActiveComponent(e.target.name);
  };

  return (
    <>
      <FadeInLeftDiv>
        <MenuFixed>
          <MenuScroll>
            <MenuList>
              <MenuItem>
                <SideBarTitle data-testid="profile-header">
                  {t("my_health_plan")}
                </SideBarTitle>
              </MenuItem>
              <MenuItem onClick={() => setShowMenu(!showMenu)}>
                <SideBarContent name="my_health_plan__info" onClick={handleChange}>
                  <FaUserAlt />
                  <SidebarLabel name="my_health_plan__info" onClick={handleChange}>
                    {t("my_health_plan__info")}
                  </SidebarLabel>
                </SideBarContent>
              </MenuItem>
              <MenuItem onClick={() => setShowMenu(!showMenu)}>
                <SideBarContent name="my_health_plan__plan" onClick={handleChange}>
                  <FaDatabase/>
                  <SidebarLabel name="my_health_plan__plan" onClick={handleChange}>
                    {t("my_health_plan__plan")}
                  </SidebarLabel>
                </SideBarContent>
              </MenuItem>
              <MenuItem onClick={() => setShowMenu(!showMenu)}>
                <SideBarContent name="my_health_plan__digital_id" onClick={handleChange}>
                  <FaCreditCard/>
                  <SidebarLabel name="my_health_plan__digital_id" onClick={handleChange}>
                    {t("my_health_plan__digital_id")}
                  </SidebarLabel>
                </SideBarContent>
              </MenuItem>
              <MenuItem onClick={() => setShowMenu(!showMenu)}>
              <SideBarContent name="my_health_plan__dependents" onClick={handleChange}>
                <FaUsers/>
                <SidebarLabel name="my_health_plan__dependents" onClick={handleChange}>
                  {t("my_health_plan__dependents")}
                </SidebarLabel>
              </SideBarContent>
              </MenuItem>
              <MenuItem onClick={() => setShowMenu(!showMenu)}>
                <SideBarContentFD target="_blank" href={t("find_doctor")}>
                  <FaSearch/>
                  <SidebarLabelFD data-testid="find-doctor-link" target="_blank" href={t("find_doctor")}>
                    {t("my_health_plan__find_doctor")}
                  </SidebarLabelFD>
                </SideBarContentFD>
              </MenuItem>
              <MenuItem onClick={() => setShowMenu(!showMenu)}>
                <SideBarContent name="my_health_plan__providers" onClick={handleChange}>
                  <img src={location_black} width={20} height={20} alt="location black" />
                  <SidebarLabel data-testid="medical-provider-link" name="my_health_plan__providers" onClick={handleChange}>
                    {t("my_health_plan__providers")}
                  </SidebarLabel>
                </SideBarContent>
              </MenuItem>
              <MenuItem onClick={() => setShowMenu(!showMenu)}>
                <SideBarContent name="my_health_plan__videos" onClick={handleChange}>
                  <FaPhotoVideo/>
                  <SidebarLabel data-testid="videos-link" name="my_health_plan__videos" onClick={handleChange}>
                    {t("my_health_plan__videos")}
                  </SidebarLabel>
                </SideBarContent>
              </MenuItem>
            </MenuList>
          </MenuScroll>
        </MenuFixed>
      </FadeInLeftDiv>
    </>
  );
}
