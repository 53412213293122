import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Main,
  MainContainer,
  Title,
  TitleLocation,
  Icon,
  SearchContainer,
  ButtonGoBack,
  SearchButton,
  SearchInput,
  LocationContainer,
  LocationIcon,
  LocationText,
  ContainerDown,
  Column,
  DetailCard,
  Hours,
  TitleDetail,
  List,
  Item,
  SearchSelect,
  MainSearch,
  Small,
  Flex,
  Col,
  StyledLink,
  StyledImg,
  OpcionOne,
  OpcionTwo
} from "./styles";
import LocationGreen from "../../../assets/images/location-green.svg";
import location_image from "../../../assets/images/location.png";
import location_icon from "../../../assets/images/location-icon.png";
import { SearchLocation } from "components/common/SearchLocation";
import { MapContext } from "context/MapContext";
import MapComponent from "components/layout/MapComponent";

export default function MapUrgentCareProviders() {
  const { t } = useTranslation();
  const {
    hide,
    searchType,
    setSearchType,
    handleLocationClick,
    filteredClinics,
    estimatedTimes,
    handleDetail,
    selectedClinicId,
    locations,
    panTo,
    searchValue,
    setSearchValue,
    handleSearch,
    handleKeyDown,
    isDisable,
    handleChangePageBack,
  } = useContext(MapContext);

  return (
    <>
      {!hide ? (
        <Main>
          <Flex>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Title>{t("text_urgent_care_provider_locator")}</Title>
                <p style={{ marginBottom: "1rem", fontSize: "larger", color: "#000" }}>{t('text_find')}</p>
                <Small style={{ marginBottom: "2rem" }}>{t("text_emergency")}</Small>
              </div>
            </Col>
            <OpcionOne>
                <h4 style={{ width: "150px" }}>{t("opcion_one")}:</h4> 
                <p>
                  {t("text_write")} 
                  <span> {t("find_button")}.</span>
                </p>
            </OpcionOne>
            <SearchContainer>
              <SearchSelect
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
                style={{ marginRight: "1rem", padding: "0.5rem" }}
              >
                <option value="provider">{t("search_by_provider")}</option>
                <option value="location">{t("search_by_location")}</option>
              </SearchSelect>
              <MainSearch>
                {searchType === "provider" && (
                  <SearchInput
                    type="text"
                    placeholder={t("search_provider")}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{ marginRight: "1rem", padding: "0.5rem" }}
                  />
                )}
                {searchType === "location" && (
                  <SearchLocation panTo={panTo} />
                )}
                <SearchButton type="button" onClick={handleSearch}>
                  <Icon src={location_icon} alt="icon" /> {t("find_button")}
                </SearchButton>
              </MainSearch>
            </SearchContainer>
            <div style={{ display: "flex", flexDirection: "column-reverse", alignItems: "center" }}>
              <div>
                <LocationContainer onClick={handleLocationClick}>
                  <LocationIcon src={location_image} alt="location" />
                  <LocationText>{t("use_current_location")}</LocationText>
                </LocationContainer>
              </div>
              <br />
              <div>
                <OpcionTwo>
                  <h4 style={{ width: "150px" }}>{t("opcion_two")}:</h4>
                  <p>
                    {t("text_write_google")}
                  </p>
                </OpcionTwo>
              </div>
            </div>
          </Flex>
          <div style={{ display: "none" }}>
            <MapComponent />
          </div>
        </Main>
      ) : (
        <MainContainer>
          <Title>{t("text_urgent_care_provider_locator")}</Title>
          <SearchContainer>
            <ButtonGoBack onClick={handleChangePageBack} type="button">
              {"<"} {t("go_back")}
            </ButtonGoBack>
            <SearchSelect
              value={searchType}
              disabled={isDisable}
              onChange={(e) => setSearchType(e.target.value)}
              style={{ marginRight: "1rem", padding: "0.5rem" }}
            >
              <option value="provider">{t("search_by_provider")}</option>
              <option value="location">{t("search_by_location")}</option>
            </SearchSelect>
            <MainSearch>
              {searchType === "provider" && (
                <SearchInput
                  type="text"
                  placeholder={t("search_provider")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  style={{ marginRight: "1rem", padding: "0.5rem" }}
                />
              )}
              {searchType === "location" && <SearchLocation panTo={panTo} />}
              <SearchButton type="button" onClick={handleSearch}>
                <Icon src={location_icon} alt="icon" /> {t("find_button")}
              </SearchButton>
              {/* <SearchButton
                onClick={reloadCenter}
                style={{
                  display: isReturnlocation ? "block" : "none"
                }}
              >
                {t('return_to_my_location')}
              </SearchButton> */}
            </MainSearch>
          </SearchContainer>
          {/* Google maps */}
          <MapComponent />
          {/* Google maps */}
          <ContainerDown>
            <Column
              style={{ height: "35vh", width: "50%", overflowY: "scroll" }}
            >
              {filteredClinics?.length > 0 ? (
                filteredClinics.map((data) => {
                  const estimatedTime = estimatedTimes.find(
                    (time) => time.provider === data.provider
                  );

                  return (
                    <DetailCard
                      key={data.id}
                      onClick={() => handleDetail({ ...data })}
                    >
                      <TitleLocation>
                        <img src={LocationGreen} alt="location blue" />
                        {data.provider}
                      </TitleLocation>
                      <Hours style={{ marginLeft: "2rem" }}>
                        {data.hour}
                        {/* {distance && (
                          <p>{`Distance: ${distance.toFixed(2)} km`}</p>
                        )} */}
                        <p>
                          {estimatedTime ? (
                            <>
                              {t("text_estimated_time")}:{" "}
                              {estimatedTime.estimatedTime.hours}{" "}
                              {estimatedTime.estimatedTime.hours > 1
                                ? t("hours")
                                : t("hour")}{" "}
                              {estimatedTime.estimatedTime.minutes}{" "}
                              {estimatedTime.estimatedTime.minutes > 1
                                ? t("minutes")
                                : t("minute")}
                            </>
                          ) : (
                            <></>
                            // <p>{t('estimated_time_not_available')}</p>
                          )}
                        </p>
                      </Hours>
                    </DetailCard>
                  );
                })
              ) : (
                <center>
                  <p style={{ marginTop: "2rem" }}>{t("no_nearby_places")}</p>
                </center>
              )}
            </Column>
            <Column style={{ width: "50%" }}>
              {selectedClinicId && (
                <div>
                  <TitleDetail>
                    {
                      locations?.find(
                        (clinic) => clinic.id === selectedClinicId
                      ).provider
                    }
                  </TitleDetail>
                  <List>
                    {locations
                      ?.filter((clinic) => clinic.id === selectedClinicId)
                      .map((clinic) => (
                        <Item key={clinic.id}>
                          <div>
                            <p>
                              <a
                                href={`${clinic.url_location}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {clinic.provider_address}
                              </a>
                            </p>
                            <p>{clinic.hours}</p>
                            <p>
                              {" "}
                              <a href={`tel:${clinic.telephone}`}>
                                {clinic.telephone}
                              </a>
                            </p>
                          </div>
                          {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <label htmlFor="">{t("website")}:</label>
                            <StyledLink
                              href={clinic.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {clinic.website}
                            </StyledLink>
                          </div> */}
                          {/* <div>
                            <label htmlFor="">{t("imaging_services")}:</label>
                            <p>
                              {clinic.imaging_service ||
                                `${t("not_available")}`}
                            </p>
                          </div>
                          <div>
                            <label htmlFor="">{t("laboratory")}:</label>
                            <p>
                              {clinic.laboratory || `${t("not_available")}`}
                            </p>
                          </div> */}
                        </Item>
                      ))}
                  </List>
                </div>
              )}
            </Column>
          </ContainerDown>
        </MainContainer>
      )}
    </>
  );
}
