import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { getGeocode, getLatLng } from "react-places-autocomplete";
import axiosClient from "config/axios";
export const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [hide, setHide] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [searchType, setSearchType] = useState("provider");
  const [actualLocation, setActualLocation] = useState(null);
  const [estimatedTimes, setEstimatedTimes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedClinicId, setSelectedClinicId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [addressLocation, setAdressLocation] = useState(null);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [searchLocation, setSearchLocation] = useState({ lat: null, lng: null });
  const searchRadiusKm = 30000; // Radio de búsqueda en 300 kilómetros

  const mapRef = useRef();

  /**
   * Pans the map to the given location and updates search and actual location states.
   *
   * @param {Object} location - Object with latitude and longitude.
   * @param {number} location.lat - Latitude of the target location.
   * @param {number} location.lng - Longitude of the target location.
   * @returns {void}
   */

  const panTo = useCallback(
    ({ lat, lng }) => {
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
        if (typeof zoom !== "undefined") {
          mapRef.current.setZoom(zoom);
        }
      }
      setSearchLocation({ lat, lng });
      setActualLocation({ lat, lng });
    },
    [zoom]
  );

  /**
   * Fetches urgent care locations from the API and stores them in the locations state.
   *
   * @function
   * @async
   * @returns {Promise<void>}
   */
  const fetchLocations = async () => {
    try {
      const response = await axiosClient.get("/crm/api/urgent_care_location/");
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (hide) {

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (searchType === "provider" || !actualLocation) {
            setActualLocation({ lat: latitude, lng: longitude });
            setSearchLocation({ lat: latitude, lng: longitude });
          }
          
          const searchLatitude = searchLocation && searchLocation.lat !== null ? searchLocation.lat : latitude;
          const searchLongitude = searchLocation && searchLocation.lng !== null ? searchLocation.lng : longitude;

          const locationsWithinRadius = locations.filter((location) => {
            const [lat, lng] = location.lat_long.split(",").map(Number);
            const distance = calculateDistance(searchLatitude, searchLongitude, lat, lng);
            return distance <= searchRadiusKm;
          });
          const estimatedTimesArray = locationsWithinRadius.map((location) => {
            const [lat, lng] = location.lat_long.split(",").map(Number);
            const distance = calculateDistance(searchLatitude, searchLongitude, lat, lng);
  
            const estimatedTime = distance
              ? calculateEstimatedTime(distance)
              : { hours: 0, minutes: 0 };
  
            return { provider: location.provider, estimatedTime };
          });

          setEstimatedTimes(estimatedTimesArray);
        },
        (error) => {
          console.error("Error al obtener la ubicación", error);
        },
        { timeout: 10000 }
      );
    }
  }, [hide, locations, searchRadiusKm, searchLocation]);

  /**
   * Calculate the distance in kilometers between two points on Earth
   * given their latitudes and longitudes.
   * @param {number} lat1 - Latitude of the first point.
   * @param {number} lon1 - Length of the first point.
   * @param {number} lat2 - Latitude of the second point.
   * @param {number} lon2 - Length of the second point.
   * @return {number} The distance in kilometers between the two points.
   */
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radio de la Tierra en km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const roadFactor = 1.5;
    const trafficMultiplier = 1.1;
    let distance = R * c // Distancia en kilómetros
    distance *= roadFactor
    return distance * trafficMultiplier
  };

  /**
   * Calculates the estimated time in hours and minutes that a user will take
   * to reach a clinic from your current location, given the distance in km.
   *
   * @param {number} distanceInKm The distance in km between the current location and the clinic.
   * @returns An object with properties `hours` and `minutes` that represent the
   *          estimated time in hours and minutes respectively.
   */
  const calculateEstimatedTime = (distanceInKm) => {
    if (!distanceInKm || isNaN(distanceInKm)) return { hours: 0, minutes: 0 }; // Manejo de error

    const avgSpeedKmPerHour = 40; // Velocidad promedio
    const totalMinutes = (distanceInKm / avgSpeedKmPerHour) * 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);
    return { hours, minutes };
  };

  const handleLocationClick = () => {
    setHide((prevHide) => !prevHide);
  };

  const filteredClinics = useMemo(() => {
    const searchTerm = searchValue.toLowerCase().trim();
  
    if (!actualLocation) return [];
  
    return locations
      .map((clinic) => {
        const [lat, lng] = clinic.lat_long.split(",").map(Number);
        const distance = calculateDistance(actualLocation.lat, actualLocation.lng, lat, lng);
        const estimatedTime = estimatedTimes.find((time) => time.provider === clinic.provider);
  
        return {
          ...clinic,
          distance,
          estimatedTime,
        };
      })
      .filter((clinic) => {
        if (searchType === 'location') return true;
        const isAddressMatch =
          addressLocation && addressLocation.address_components && addressLocation.address_components[0]
          ? addressLocation.address_components[0].formatted_address.toLowerCase().includes(searchTerm)
          : false;
          
        const isProviderMatch = (clinic.provider.toLowerCase().includes(searchTerm) || 
          clinic.provider_address.toLowerCase().includes(searchTerm));
        return (isAddressMatch || isProviderMatch);
      })
      
      .filter((clinic) => {
        if (searchType === 'provider') return true;
        return clinic.distance <= searchRadiusKm / 1000;
      })
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 4);
  }, [
    actualLocation,
    locations,
    estimatedTimes,
    calculateDistance,
    searchValue,
    addressLocation,
  ]);
  

/**
 * Updates the selected clinic details based on the provided data.
 * If the data contains an ID different from the current selected clinic ID,
 * it updates the selected clinic ID to the new ID and sets the map zoom level to 12.
 *
 * @param {Object} data - The clinic data object containing the ID.
 */

  const handleDetail = (data) => {
    if (data && data.id !== selectedClinicId) {
      setSelectedClinicId(data.id);
      setZoom(12);
    }
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

/**
 * Handles the search functionality based on the current search value.
 * If the search value length is greater than 1, it hides certain UI elements.
 * Attempts to retrieve geocode information for the given search value.
 * If successful, pans the map to the resulting latitude and longitude.
 * Logs an error message if the geocode retrieval fails.
 * Disables certain UI elements after the search attempt.
 */

  const handleSearch = async () => {
    try {
      if (searchValue.length > 1) {
        setHide(true);
        return;
      }

      const results = await getGeocode({ address: searchValue });
      if (results.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        const newCenter = { lat, lng };
        panTo(newCenter);
      }
    } catch (error) {
      console.error("Error en la búsqueda de ubicación o proveedor:", error);
    } finally {
      setIsDisable(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
      setIsDisable(true);
    }
  };

  
  const handleChangePageBack = () => {
    setHide(false);
    setSearchValue("");
    setSelectedClinicId(null);
    setZoom(10);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        if (searchType === "location") {
          setActualLocation({ lat: latitude, lng: longitude });
        }
      },
      (error) => {
        console.error("Error al obtener la ubicación:", error);
      }
    );
  };
  
  return (
    <MapContext.Provider
      value={{
        hide,
        setHide,
        searchType,
        setSearchType,
        actualLocation,
        handleLocationClick,
        searchRadiusKm,
        locations,
        estimatedTimes,
        calculateDistance,
        filteredClinics,
        handleDetail,
        selectedClinicId,
        zoom,
        setZoom,
        panTo,
        searchValue,
        setSearchValue,
        handleSearch,
        handleKeyDown,
        onMapLoad,
        isDisable,
        setActualLocation,
        setZoom,
        setSelectedClinicId,
        handleChangePageBack,
        setAdressLocation,
        setCoordinates
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
