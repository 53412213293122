/***
 * This components contains a parameter that contain
 * a name to change the component
 */
import React, {useContext, useEffect} from "react";
import { authContext } from "context/authContext";
import FindDoctor from "./FindDoctor";
import Videos from "./Videos";
import Credential from "./Credential";
import MedicalProviders from "./MedicalProviders";
import MapUrgentCareProviders from "../MapUrgentCareProviders";
import Dependant from "./Dependant";
import UserInfo from "./UserInfo";
import UserPlan from "./UserPlan";

import axiosClient from "config/axios";

const DataContent = ({ isActive }) => {
  const my_health_plan__info = "my_health_plan__info"
  const my_health_plan__digital_id = "my_health_plan__digital_id"
  const my_health_plan__find_doctor = "my_health_plan__find_doctor"
  const my_health_plan__providers = "my_health_plan__providers"
  const my_health_plan__plan = "my_health_plan__plan"
  const my_health_plan__videos = "my_health_plan__videos"
  const my_health_plan__dependents = "my_health_plan__dependents"
  const PAGES = {
    [my_health_plan__info]: <UserInfo />,
    [my_health_plan__digital_id]: <Credential />,
    [my_health_plan__find_doctor]: <FindDoctor />,
    [my_health_plan__providers]: <MapUrgentCareProviders />,
    [my_health_plan__plan]: <UserPlan />,
    [my_health_plan__videos]: <Videos />,
    [my_health_plan__dependents]: <Dependant />,
  };

  const authState = useContext(authContext);
  const { setLogout } = authState;

  /**
   * Validates the user session
   * @date 2022-07-06
   */
  const validateSession = () => {
    axiosClient
      .get("/auth/userdata/")
      .then((response) => {
      })
      .catch((error) => {
        setLogout();
      });
  }

  useEffect(() => {
    validateSession();
  }, [isActive])

  return PAGES[isActive];
};

export default DataContent;
