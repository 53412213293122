import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  font-family: Arial, sans-serif;

  @media (max-width: 480px) {
    padding: 0;
    justify-content: start;
  }
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
`;

export const Icon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
`;

export const Title = styled.h2`
  color: #000;
  margin: 1rem 0;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export const TitleLocation = styled.h1`
  display: flex;
  align-items: center;
  font-size: larger;
  color: #005487;
  margin: 0;

  p {
    font-size: small;
  }

  @media (max-width: 768px) {
    margin: 0;
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: .8rem;

      
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 900px;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SearchInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  outline: none;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

export const SearchSelect = styled.select`
  border: 1px solid #555;
  border-radius: 5px;
  padding: 0.5rem;
  margin-right: 0 !important;
  outline: none;
  color: #555;
  font-weight: bold;
  height: 37px;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    width: 100%;
  }
`;

export const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: white;
  background-color: #005487;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all.1s;

  &:hover {
    background-color: #1565c0;
  }

  &:active {
    background-color: #1e88e5;
    transition: all.1s;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }

  @media (max-width: 480px) {
    padding: 0.5rem 0;
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2rem;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const LocationIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
`;

export const LocationText = styled.p`
  color: #426ab1 !important;
  font-size: 1.5rem;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`;

export const ButtonGoBack = styled.button`
  background: none;
  box-shadow: none;
  border: none;
  margin-right: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  color: #426ab1;
  padding: 1rem;
  width: 150px;
`;

export const ContainerDown = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: .5rem;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 768px) {
    gap: 0;
  }
`;

export const Column = styled.div`
  /* flex: 1;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1330px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  } */
`;

export const DetailCard = styled.div`
  border-radius: 8px;
  transition: transform 0.2s;
  cursor: pointer;
  width: 100%;
  padding: 0 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Address = styled.p`
  margin: 5px 0;
  color: #666;
`;

export const Phone = styled.p`
  margin: 5px 0;
  color: #666;
`;

export const Hours = styled.p`
  color: #666;

  @media (max-width: 480px) {
    font-size: .8rem;
  }
`;

export const WebsiteLink = styled.a`
  color: #005487;
  text-decoration: underline;
  transition: color 0.2s;

  &:hover {
    color: #0056b3;
  }
`;

export const TitleDetail = styled.h3`
  letter-spacing: 3px;
  font-size: x-large;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: medium;
  }
`;

export const List = styled.ul`
  list-style: none;
`;

export const Item = styled.li`
  div {
    padding-bottom: 1rem;
  }

  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #555555;
  }

  label {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    a {
      font-size: small;
    }
    label {
      font-size: small;
    }
  }

  @media (max-width: 480px) {
    p {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export const StyledLink = styled.a`
  @media (max-width: 768px) {
    font-size: .6rem;
  }
`;

export const MainSearch = styled.main`
  border: 1px solid #555;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;    
  }
`;

export const Small = styled.h4`
  font-style: italic;
  padding-bottom: 0.5rem;
  color: #005487;
  font-weight: bold;
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-content: space-between;
    height: 550px;
    font-size: larger;
`;

export const Col = styled.div`
  //width: 24.999999999%;

  h4 {
    color: #005487;
    font-weight: bold;
  }

  p {
    color: #555555;
  }

  span {
    color: #005487;
    font-weight: bold;
  }
`;

export const OpcionOne = styled.div`
  width: 1000px;
  font-size: larger;
  display: flex;

  h4 {
    color: #005487;
  }

  p {
    color: #000;
    display: block;
    font-weight: 300;
  }

  span {
    color: #005487;
    font-weight: bold;
  }
`;

export const OpcionTwo = styled.div`
  width: 1000px;
  font-size: larger;
  display: flex;

  h4 {
    color: #005487;
    font-weight: bold;
  }

  p {
    color: #000;
    font-weight: 300;
  }

  span {
    color: #005487;
    font-weight: bold;
  }
`;

export const StyledImg = styled.img`
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;